import {
  Order,
  LocationDeliveryType,
  OrderClient,
  OrderDelivery,
  OrderDeliveryStatus,
  OrderDeliveryType,
  OrderInvoice,
  OrderItem,
  OrderStatus,
  RejectionReasons,
  SupportedProviders,
  AllRejectionReasons,
} from '@/interfaces';
import { statusColors } from '@/helpers/statuses';
import { toLocalDate, toLocale } from '@/helpers/date';
import { OrderItemMod, OrderStatusHistory, PaymentMethod } from '@/interfaces/order.interface';
import { ConceptModel, OrderDeliveryModel, OrderItemModel, OrderStatusHistoryModel } from '.';
import { formatPrice } from '@/helpers/price';

export class OrderModel implements Order {
  id!: number;
  conceptId!: number;
  locationId!: number;
  source!: SupportedProviders;
  externalId!: string;
  externalId2!: string;
  pickupTime!: string;
  disposable!: boolean;
  value!: number;
  deliveryCosts!: number;
  promotions!: number;
  valueAfterPromotions?: number;
  orderSummary?: number;
  allergies?: string;
  comments?: string;
  delivery?: OrderDelivery;
  invoice?: OrderInvoice;
  status!: OrderStatus;
  client!: OrderClient;
  deliveryType!: OrderDeliveryType;
  deliveryBy!: LocationDeliveryType;
  createdAt!: string;
  items!: OrderItem[];
  statuses?: OrderStatusHistory[];
  concept!: ConceptModel;

  paymentMethod!: PaymentMethod;
  createdAtDate?: string;
  rejectionReason?: RejectionReasons | AllRejectionReasons;

  constructor(data: Partial<Order>, locale: string) {
    Object.assign(this, data);

    this.createdAtDate = toLocalDate(this.createdAt, locale);
    this.createdAt = toLocale(this.createdAt);

    if (data?.statuses?.length) {
      this.statuses = data.statuses.map((status) => new OrderStatusHistoryModel(status));
    }

    this.value = +this.value;
    this.promotions = +this.promotions;
    this.deliveryCosts = +this.deliveryCosts;

    this.delivery = this.delivery && new OrderDeliveryModel(this.delivery);
    this.concept = this.concept && new ConceptModel(this.concept);
    this.items = this.items?.map(
      (item) => new OrderItemModel(item, this.concept.getCurrencyFormat())
    );
  }

  updateProperties(data: Partial<Order>): void {
    Object.keys(data).forEach((key) => {
      const value = data[key];
      if (value !== undefined) {
        this[key] = value;
      }
    });
  }

  getExternalId(): string {
    return this.externalId2 || this.externalId;
  }

  getExternalOrderId(): string {
    return this.externalId || this.externalId2;
  }

  hasSelfDelivery(): boolean {
    return Boolean(this.delivery?.externalId2);
  }

  hasProblemWithCourier(): boolean {
    return Boolean(
      this.delivery?.status &&
        [
          OrderDeliveryStatus.pickup_cancelled,
          OrderDeliveryStatus.pickup_failed,
          OrderDeliveryStatus.pickup_paused,
          OrderDeliveryStatus.drop_cancelled,
          OrderDeliveryStatus.drop_failed,
          OrderDeliveryStatus.drop_cancelled,
        ].includes(this.delivery?.status)
    );
  }

  hasInvoice(): boolean {
    return Boolean(this.invoice?.id);
  }

  getStatusColor(): string {
    return statusColors[this.status];
  }

  getDeliveryName(): LocationDeliveryType | OrderDeliveryType {
    return this.deliveryType === OrderDeliveryType.delivery_by_us
      ? this.deliveryBy
      : this.deliveryType;
  }

  getSubTotalValue(): number {
    return this.value;
  }

  canOrderCourier(): boolean {
    return Boolean(
      this.deliveryType === OrderDeliveryType.delivery_by_us &&
        this.deliveryBy !== LocationDeliveryType.delivery_by_restaurant &&
        this.delivery &&
        !this.delivery.externalId
    );
  }

  isCanceledWithSelfDelivery(): boolean {
    return Boolean(
      this.deliveryType === OrderDeliveryType.delivery_by_us &&
        this.status === OrderStatus.rejected &&
        this.rejectionReason !== AllRejectionReasons.expired
    );
  }

  canAutomaticallyCancelCourier(): boolean {
    const canCancel: Record<LocationDeliveryType, boolean> = {
      [LocationDeliveryType.delivery_by_provider]: true,
      [LocationDeliveryType.delivery_by_restaurant]: true,
      // [LocationDeliveryType.delivery_by_ld]: false,
      [LocationDeliveryType.delivery_by_stava]: false,
      [LocationDeliveryType.delivery_by_stuart]: true,
      [LocationDeliveryType.delivery_by_deligoo]: true,
      [LocationDeliveryType.delivery_by_woltdrive]: false,
      [LocationDeliveryType.delivery_by_glovo_on_demand]: true,
    };

    return canCancel[this.deliveryBy];
  }

  getInternalOrderId(): string | undefined {
    return this.hasSelfDelivery() ? this.delivery?.externalId2 : this.getExternalId();
  }

  getSubTotalValueWithCurrency(): string {
    return formatPrice(this.getSubTotalValue(), this.concept.getCurrencyFormat());
  }

  getPromotionsValueWithCurrency(): string {
    return this.promotions === 0
      ? `${formatPrice(0, this.concept.getCurrencyFormat())}`
      : `${formatPrice(+this.promotions.toFixed(2), this.concept.getCurrencyFormat())}`;
  }

  getValueWithCurrency(): string {
    return formatPrice(this.valueAfterPromotions as number, this.concept.getCurrencyFormat());
  }

  getDeliveryValueWithCurrency(): string {
    return formatPrice(this.deliveryCosts, this.concept.getCurrencyFormat());
  }

  getOrderSummaryValue(): string {
    return formatPrice(this.orderSummary as number, this.concept.getCurrencyFormat());
  }

  get isCanceledByMarketplaceOrExpired(): boolean {
    return (
      this.status == OrderStatus.rejected &&
      (this.rejectionReason === AllRejectionReasons.canceled_by_marketplace ||
        this.rejectionReason === AllRejectionReasons.expired)
    );
  }

  get isCanceledByRestaurant(): boolean {
    return (
      this.status == OrderStatus.rejected &&
      this.rejectionReason !== AllRejectionReasons.canceled_by_marketplace &&
      this.rejectionReason !== AllRejectionReasons.expired
    );
  }

  get canSetPrepTime(): boolean {
    return !['Glovo', 'JustEat'].includes(this.source);
  }

  get canBeAutoCanceled(): boolean {
    return !['Glovo', 'JustEat'].includes(this.source);
  }
}
